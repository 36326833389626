* {
   box-sizing: content-box;
 }
 
 html,
 body {
   overscroll-behavior-y: contain;
   margin: 0;
   padding: 0;
   height: 100%;
   width: 100%;
   user-select: none;
   font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial,
     sans-serif;
 }
 
 #photo-deck {
   /* background: lightblue; */
   position: fixed;
   overflow: hidden;
   width: 100%;
   height: 100%;
 }
 
 #photo-deck > div {
   position: absolute;
   width: 100vw;
   height: 100vh;
   will-change: transform;
   display: flex;
   align-items: center;
   justify-content: center;
 }
 
 #photo-deck > div > div {
   background-color: #fff;
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center center;
   will-change: transform;
   border: 1em solid #fff;
   border-bottom: 4em solid #fff;
   border-radius: 3px;
   box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
 }