@tailwind base;
@tailwind components;
@tailwind utilities;

body {
   background-color: #F6F1F1
}

/* 
Color Palette
#F6F1F1
#AFD3E2
#19A7CE
#146C94
*/
