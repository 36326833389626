/**
 * @license
 *
 * Font Family: Author
 * Designed by: Satya Rajpurohit
 * URL: https://www.fontshare.com/fonts/author
 * © 2023 Indian Type Foundry
 *
 * Font Styles:
 * Author Variable(Variable font)
 * Author Variable Italic(Variable font)
 * Author Extralight
 * Author Extralight Italic
 * Author Light
 * Author Light Italic
 * Author Regular
 * Author Italic
 * Author Medium
 * Author Medium Italic
 * Author Semibold
 * Author Semibold Italic
 * Author Bold
 * Author Bold Italic
 *
*/


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 700.0;
*
* available axes:

* 'wght' (range from 200.0 to 700.0)

*/

@font-face {
  font-family: 'Author-Variable';
  src: url('../fonts/Author-Variable.woff2') format('woff2'),
       url('../fonts/Author-Variable.woff') format('woff'),
       url('../fonts/Author-Variable.ttf') format('truetype');
       font-weight: 200 700;
       font-display: swap;
       font-style: normal;
}


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 700.0;
*
* available axes:

* 'wght' (range from 200.0 to 700.0)

*/

@font-face {
  font-family: 'Author-VariableItalic';
  src: url('../fonts/Author-VariableItalic.woff2') format('woff2'),
       url('../fonts/Author-VariableItalic.woff') format('woff'),
       url('../fonts/Author-VariableItalic.ttf') format('truetype');
       font-weight: 200 700;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Author-Extralight';
  src: url('../fonts/Author-Extralight.woff2') format('woff2'),
       url('../fonts/Author-Extralight.woff') format('woff'),
       url('../fonts/Author-Extralight.ttf') format('truetype');
       font-weight: 200;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Author-ExtralightItalic';
  src: url('../fonts/Author-ExtralightItalic.woff2') format('woff2'),
       url('../fonts/Author-ExtralightItalic.woff') format('woff'),
       url('../fonts/Author-ExtralightItalic.ttf') format('truetype');
       font-weight: 200;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Author-Light';
  src: url('../fonts/Author-Light.woff2') format('woff2'),
       url('../fonts/Author-Light.woff') format('woff'),
       url('../fonts/Author-Light.ttf') format('truetype');
       font-weight: 300;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Author-LightItalic';
  src: url('../fonts/Author-LightItalic.woff2') format('woff2'),
       url('../fonts/Author-LightItalic.woff') format('woff'),
       url('../fonts/Author-LightItalic.ttf') format('truetype');
       font-weight: 300;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Author-Regular';
  src: url('../fonts/Author-Regular.woff2') format('woff2'),
       url('../fonts/Author-Regular.woff') format('woff'),
       url('../fonts/Author-Regular.ttf') format('truetype');
       font-weight: 400;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Author-Italic';
  src: url('../fonts/Author-Italic.woff2') format('woff2'),
       url('../fonts/Author-Italic.woff') format('woff'),
       url('../fonts/Author-Italic.ttf') format('truetype');
       font-weight: 400;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Author-Medium';
  src: url('../fonts/Author-Medium.woff2') format('woff2'),
       url('../fonts/Author-Medium.woff') format('woff'),
       url('../fonts/Author-Medium.ttf') format('truetype');
       font-weight: 500;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Author-MediumItalic';
  src: url('../fonts/Author-MediumItalic.woff2') format('woff2'),
       url('../fonts/Author-MediumItalic.woff') format('woff'),
       url('../fonts/Author-MediumItalic.ttf') format('truetype');
       font-weight: 500;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Author-Semibold';
  src: url('../fonts/Author-Semibold.woff2') format('woff2'),
       url('../fonts/Author-Semibold.woff') format('woff'),
       url('../fonts/Author-Semibold.ttf') format('truetype');
       font-weight: 600;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Author-SemiboldItalic';
  src: url('../fonts/Author-SemiboldItalic.woff2') format('woff2'),
       url('../fonts/Author-SemiboldItalic.woff') format('woff'),
       url('../fonts/Author-SemiboldItalic.ttf') format('truetype');
       font-weight: 600;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Author-Bold';
  src: url('../fonts/Author-Bold.woff2') format('woff2'),
       url('../fonts/Author-Bold.woff') format('woff'),
       url('../fonts/Author-Bold.ttf') format('truetype');
       font-weight: 700;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Author-BoldItalic';
  src: url('../fonts/Author-BoldItalic.woff2') format('woff2'),
       url('../fonts/Author-BoldItalic.woff') format('woff'),
       url('../fonts/Author-BoldItalic.ttf') format('truetype');
       font-weight: 700;
       font-display: swap;
       font-style: italic;
}

